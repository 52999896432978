import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Confirm } from 'components/confirm';
import { CopyInput } from 'components/input';
import { MetadataUrlStep } from 'components/sso/steps/saml/metadata-url-step';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export const ClassLinkCreateApplication: React.FC<
  Readonly<ConnectionStepProps>
> = ({ appName, connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">
      Step 1: Create and configure a ClassLink SAML Application
    </Title>

    <Text>
      Create a SAML application in your ClassLink instance. You'll need the
      following Assertion Consumer Service (ACS) URL. The ACS URL is the
      location ClassLink will redirect its authentication response to.
    </Text>

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <Text>
      The Service Provider Entity ID is a unique value which represents{' '}
      {appName} within ClassLink. Provide the value below to your ClassLink SAML
      application settings.
    </Text>

    <CopyInput
      label="Copy this SP Entity ID"
      value={connection?.saml_entity_id}
    />

    <Text>
      For some setups, you can use the metadata found at the following SP
      Metadata link to configure the SAML connection.
    </Text>

    <CopyInput
      label="Copy this URL/XML"
      value={connection?.saml_sp_metadata_url}
    />

    <Confirm
      label="I've created and configured a SAML application."
      onClick={onNextStep}
    />
  </Article>
);

export const ClassLinkUploadMetadata: React.FC<
  Readonly<ConnectionStepProps>
> = (connectionStepProps) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://myclasslinkserver.com/abc123..."
    idpSsoUrlPlaceholder="https://myclasslinkserver.com/..."
    metadataUrlPlaceholder="https://"
    stepTitle="Step 2: Upload Identity Provider Metadata"
  >
    <Text>
      To connect with your ClassLink instance, please upload the ClassLink SAML
      application metadata.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Text>
      Upload the IdP metadata URL from your SAML settings. Alternatively, you
      can manually configure the connection by providing the SSO URL, IdP Issuer
      and X.509 Certificate.
    </Text>
  </MetadataUrlStep>
);

export const ClassLinkConfigureClaims: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 3: Configure your SAML Claims</Title>

    <Text>
      The final step is to confirm that user attributes map to the following
      SAML claims.
    </Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Confirm
      label="I've finished configuring the SAML claims."
      onClick={onNextStep}
    />
  </Article>
);
